import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        
        {
            path: '/',
            redirect: '/auth/'
        },
        
        {
            path: '/auth/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/auth/Auth.vue')
        },
        
        {
            path: '/dashboard/pages/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/pages/Pages.vue')
        },

        {
            path: '/dashboard/pages/edit/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/pages/edit/Edit.vue')
        },
        
        {
            path: '/dashboard/fd/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/fd/FileDictionary.vue')
        },

        {
            path: '/dashboard/fd/brand/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/fd/brand/Brand.vue')
        },

        {
            path: '/dashboard/fd/files/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/fd/files/Files.vue')
        },

        {
            path: '/dashboard/fd/quick-info/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/fd/quick-info/QuickInfo.vue')
        },
        
        {
            path: '/dashboard/file-service/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/file-service/FileService.vue')
        },

        {
            path: '/dashboard/file-service/categories/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/file-service/categories/Categories.vue')
        },
        
        {
            path: '/dashboard/file-service/edit/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/file-service/edit/Edit.vue')
        },

        {
            path: '/dashboard/file-service/topics/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/file-service/topics/Topics.vue')
        },

        {
            path: '/dashboard/file-service/edit-required-fields/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/file-service/edit-required-fields/EditRequiredFields.vue')
        },
        
        {
            path: '/dashboard/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/start/Start.vue')
        },

        {
            path: '/dashboard/payments/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/payments/Payments.vue')
        },
        
        {
            path: '/dashboard/credit-payments/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/credit-payments/CreditPayments.vue')
        },

        {
            path: '/dashboard/balances-log/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/balances-log/BalancesLog.vue')
        },
        
        {
            path: '/dashboard/price-management/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/price-management/PriceManagement.vue')
        },
        
        {
            path: '/dashboard/admin-links/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/admin-links/AdminLinks.vue')
        },

        {
            path: '/dashboard/download-statistics/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/download-statistics/DownloadStatistics.vue')
        },

        {
            path: '/dashboard/query-statistics/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/query-statistics/QueryStatistics.vue')
        },

        {
            path: '/dashboard/auth-log/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/auth-log/AuthLog.vue')
        },

        {
            path: '/dashboard/news/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/news/News.vue')
        },

        {
            path: '/dashboard/news/edit/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/news/edit/NewsEdit.vue')
        },
        
        {
            path: '/dashboard/mail-templates/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/mail-templates/MailTemplates.vue')
        },
        
        {
            path: '/dashboard/mail-templates/edit/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/mail-templates/edit/Edit.vue')
        },

        {
            path: '/dashboard/emails/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/emails/Emails.vue')
        },

        {
            path: '/dashboard/emails/view/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/emails/view/View.vue')
        },
        
        {
            path: '/dashboard/mailing-lists/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/mailing-lists/MailingLists.vue')
        },

        {
            path: '/dashboard/mailing-lists/feedback-mails/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/mailing-lists/target-mails/FeedbackMails.vue')
        },
        
        {
            path: '/dashboard/stop-words/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/stop-words/StopWords.vue')
        },

        {
            path: '/dashboard/discounts/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/discounts/Discounts.vue')
        },

        {
            path: '/dashboard/promocodes/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/promocodes/Promocodes.vue')
        },

        {
            path: '/dashboard/promocodes/edit/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/promocodes/edit/PromocodeEdit.vue')
        },

        {
            path: '/dashboard/indexing/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/indexing/Indexing.vue')
        },

        {
            path: '/dashboard/server/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/server/Server.vue')
        },

        {
            path: '/dashboard/social/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/social/Social.vue')
        },

        {
            path: '/dashboard/reports/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/reports/Reports.vue')
        },

        {
            path: '/dashboard/settings/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/settings/Settings.vue')
        },

        {
            path: '/dashboard/sales/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/sales/Sales.vue')
        },
        
        {
            path: '/dashboard/search/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/search/Search.vue')
        },

        {
            path: '/dashboard/search/redirect/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/search/redirect/Redirect.vue')
        },
        
        {
            path: '/dashboard/bans/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/bans/Bans.vue')
        },

        {
            path: '/dashboard/users/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/users/Users.vue')
        },

        {
            path: '/dashboard/users/ip/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/users/ip/IP.vue')
        },

        {
            path: '/dashboard/users/user/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/users/user/User.vue')
        },

        {
            path: '/dashboard/statuses/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/statuses/Statuses.vue')
        },
        
        {
            path: '/dashboard/ads/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/ads/Ads.vue')
        },

        {
            path: '/dashboard/ads/edit/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/ads/edit/AdsEdit.vue')
        },
        
        {
            path: '/dashboard/logout/',
            component: () => import(/* webpackChunkName: "dashboard" */ './../views/dashboard/logout/Logout.vue')
        },
        
    ]
});