
export default {

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    link2(relativePath) {
        var port = window.location.port;
        if ((port == 0) || (port == 80) || (port == 443)) {
            return (window.location.protocol + '//' + window.location.hostname + relativePath);
        }
        else {
            return (window.location.protocol + ":" + port + '//' + window.location.hostname + relativePath);
        }
    },
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    nav2(relativePath) {
        window.location = this.link2(relativePath);
    },
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    
};