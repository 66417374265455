<template>
    
    <div class='message-box' v-show='isVisible'>
        <div class='background'></div>
        <div class='form' @click='close'>
            {{message}}
        </div>
    </div>
    
</template>

<script>

    export default {
        data() {
            return {
                
                //////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  
                
                message: '',

                //////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                isVisible: false,

            };
        },
        created() {

            this.$root.$on('messageBox:show', this.show);

        },
        beforeDestroy() {

            this.$root.$off('messageBox:show', this.show);

        },
        methods: {
            
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            show(message) {
                this.message = message;
                this.isVisible = true;
                this.startAutoHide();
            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            close() {
                this.isVisible = false;
            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  
            
            startAutoHide() {
                var self = this;
                setTimeout(() => {
                    self.close();
                }, 5000);
            },

        }
    }

</script>

<style scoped>

    .message-box {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 10000;
    }
    
    .background {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: black;
        opacity: 0.7;
    }

    .form {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 500px;
        height: auto;
        transform: translate(-50%, -50%);
        background: white;
        color: black;
        font-size: 15px;
        padding: 80px 20px 80px 20px;
        text-align: center;
        z-index: 2;
    }

</style>