<template>

    <div class='new-price-dialog' v-show='visible'>
        <div class='background' @click='hide'></div>
        <div class='form'>
            <div class='content2'>
                <div class='label'>
                    New Price:
                </div>
                <input v-model='price' />
                <div class='button'>
                    <button @click='onConfirm'>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

    export default {
        data() {
            return {
                
                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  
                
                callback: null,

                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                price: '',
                
                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                visible: false,

            };
        },
        created() {

            this.$root.$on('newPriceDialog:show', this.show);

        },
        beforeDestroy() {

            this.$root.$off('newPriceDialog:show', this.show);

        },
        methods: {

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            show(callback) {

                this.price = '';
                this.callback = callback;
                this.visible = true;

            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            hide() {

                this.visible = false;

            },
            
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            onConfirm() {

                if (this.price == '')
                    return;

                if (this.callback) this.callback(this.price);
                this.hide();

            },
            
        }
    }

</script>

<style scoped>

    .new-price-dialog {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 1000;
    }

    .background {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: black;
        opacity: 0.5;
   }

    .form {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 250px;
        height: auto;
        transform: translate(-50%, -50%);
        z-index: 2;
        background: white;
    }

    .content2 {
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px;
        box-sizing: border-box;
    }

    .label {
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 10px 10px 0;
        box-sizing: border-box;
        color: black;
    }

    input {
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px;
        box-sizing: border-box;
        color: black;
        margin: 0 0 10px 0;
    }

    button {
        position: relative;
        width: auto;
        height: auto;
        border: 0;
        margin: 0 auto 0 auto;
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        border-radius: 4px;
        background: #2F80ED;

        font-family: Golos;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: white;
    }

    button:hover {
        background: #1d5db3;
        color: white;
    }

    button:active {
        background: #1d5db3;
        color: white;
    }

</style>