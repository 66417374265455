<template>
    
    <div class='master-password-dialog' v-show='visible'>
        
        <div class='background' @click='close'></div>

        <div class='dialog'>
            <div class='content'>
                <table>
                    <tr>
                        <td class='column'>
                            <div class='label'>
                                Enter master password:
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class='column'>
                            <input v-model='password' type='password' />
                        </td>
                    </tr>
                    <tr>
                    <td class='column'>
                        <button @click='onPasswordConfirm'>
                            Confirm
                        </button>
                    </td>
                </tr>
                </table>
            </div>
        </div>

    </div>

</template>

<script>

    import API from './../../../base/api.js'

    export default {
        data() {
            return {

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  
                
                onPasswordConfirmed: null,

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                password: '',

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                visible: false,

            };
        },
        created() {

            this.$root.$on('masterPasswordDialog:show', this.show);

        },
        beforeDestroy() {

            this.$root.$off('masterPasswordDialog:show', this.show);

        },
        methods: {
            
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            show(onPasswordConfirmed) {
                this.onPasswordConfirmed = onPasswordConfirmed;
                this.visible = true;
            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            close() {
                this.onPasswordConfirmed = null;
                this.password = '';
                this.visible = false;
            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            onPasswordConfirm() {
                if (this.onPasswordConfirmed) {
                    this.onPasswordConfirmed(this.password);
                }
                this.close();
            },
            
        }
    }

</script>

<style scoped>

    .master-password-dialog {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }

    .background {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.5;
    }

    .dialog {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 500px;
        height: 230px;
        transform: translate(-50%, -50%);
        background: white;
        border-radius: 12px;
    }

    .content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 21px;
        box-sizing: border-box;
    }

    table {
        position: relative;
        width: 458px;
        height: auto;

        border-spacing: 10px;
        border-collapse: separate;
        box-sizing: border-box;
        table-layout: fixed;

        font-family: Golos;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 26px;
    }

    .column {
        position: relative;
        width: 100%;
        height: auto;
        vertical-align: top;
    }

    .label {
        position: relative;
        width: 100%;
        height: auto;
        font-size: 14px;
        padding: 0;
        box-sizing: border-box;
    }

    input {
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 10px 10px 15px;
        box-sizing: border-box;
        border: 1px solid gray;
        border-radius: 7px;
        outline: none;
    }
    
    button {
        width: 100%;
        height: auto;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;

        padding: 15px 10px;
        margin: 10px 0 10px 0;
        border: 0;
        background: #2F80ED;
        border-radius: 4px;
        color: white;
        outline: none;
    }

    button:hover {
        background: #1e6acf;
    }

    button:active {
        background: #1959ae;
    }

</style>