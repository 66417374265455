<template>

    <div class='upload-file'>

        <form ref='uploadForm' v-show='isUploadFormVisible' enctype="multipart/form-data" novalidate>
            <div class='normal-box'>
                
                <input  ref='uploadInput'
                        class="input-file"
                        type="file"
                        accept=".*"
                        name="file"
                        />

            </div>
        </form>

        <div class='upload' v-if='view =="start"'>
            Drag the file here or click to select the desired file
        </div>
        
        <div class='upload2' v-if='view =="progress"'>
            Loading in progress
        </div>

        <div class='upload3' v-if='view =="failure"'>
            Loading error
        </div>

        <div class='upload4' v-if='view =="success"'>
            Upload complete
        </div>

    </div>

</template>

<script>

    export default {
        data() {
            return {

                /////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                view: 'start',

                /////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                isUploadFormVisible: true,

            };
        },
        created() {

            this.init();

        },
        methods: {
            
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            init() {
                var self = this;
                setTimeout(() => {
                    const selectElement = self.$refs.uploadInput;
                    selectElement.addEventListener('change', self.change);
                }, 300);
            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            getUploadUrl() {
                return 'https://api.dumptools.com/api/admin/uploadEmailFile';
            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            setState(state) {
                
                this.isUploadFormVisible = false;

                switch (state) {
                    case 'start':
                        this.isUploadFormVisible = true;
                        this.view = 'start';
                        break;
                    case 'progress':
                        this.view = 'progress';
                        break;
                    case 'failure':
                        this.view = 'failure';
                        break;
                    case 'success':
                        this.view = 'success';
                        break;
                }

            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            change(event) {
                this.filesChange(event.target.name, event.target.files);
            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            filesChange(fieldName, fileList) {

                // handle file changes
                const formData = new FormData();

                if (!fileList.length) return;

                // append the files to FormData
                Array
                .from(Array(fileList.length).keys())
                .map(x => {
                    formData.append(fieldName, fileList[x], fileList[x].name);
                });
                
                // save it
                this.save(formData);

            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            save(formData) {

                this.setState('progress');
                this.upload(this.getUploadUrl(), formData);

            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            upload(url, data) {
                
                var self = this;

                $.ajax({
                    url: url,
                    data: data,
                    type: 'POST',
                    contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
                    processData: false, // NEEDED, DON'T OMIT THIS
                    success: function(data) {
                        if (data.response) {
                            self.setState('success');
                            self.$emit('uploaded', data.response);
                        }
                        else {
                            self.setState('failure');
                        }
                    },
                    error: function() {
                        self.setState('failure');
                    },
                });

            },

        }
    }

</script>

<style scoped>

    .upload-file {
        display: inline-block;
        position: relative;
        width: 250px;
        height: 160px;
    }

    .upload {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        border: 2px dashed #B1B1B1;
        box-sizing: border-box;
        padding: 70px 10px 10px 10px;
        background: url(/images/upload.png) center 30px no-repeat;
        text-align: center;
        cursor: pointer;
    }

    .upload2 {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        border: 2px dashed #B1B1B1;
        box-sizing: border-box;
        padding: 70px 10px 10px 10px;
        background: url(/images/upload2.png) center 30px no-repeat;
        text-align: center;
    }

    .upload3 {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        border: 2px dashed #B1B1B1;
        box-sizing: border-box;
        padding: 70px 10px 10px 10px;
        background: url(/images/upload3.png) center 30px no-repeat;
        text-align: center;
    }

    .upload4 {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        border: 2px dashed #009E5C;
        box-sizing: border-box;
        padding: 70px 10px 10px 10px;
        background: url(/images/upload4.png) center 30px no-repeat;
        text-align: center;
    }

    .input-file {
        position: absolute;
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 2;
    }

    .normal-box {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        border: 2px dashed #B1B1B1;
        box-sizing: border-box;
    }

</style>