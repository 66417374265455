<template>
    
    <div class='status-change-dialog' v-show='visible'>
        
        <div class='background' @click='close'></div>

        <div class='dialog'>
            <div class='content'>
                <table>
                    <tr>
                        <td class='column'>
                            <div class='label'>
                                Choose user status:
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class='column'>
                            <multiselect v-model="value" track-by='value' label='name' placeholder="Select one" :options="valueOptions" :searchable='false' :allow-empty="true"></multiselect>
                        </td>
                    </tr>
                    <tr>
                    <td class='column'>
                        <button @click='onStatusChange'>
                            Change
                        </button>
                    </td>
                </tr>
                </table>
            </div>
        </div>

    </div>

</template>

<script>

    import API from './../../../base/api.js'

    export default {
        data() {
            return {

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                userID: 0,

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                status: '',

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  
                
                onStatusChanged: null,

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                value: null,

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                valueOptions: [],
                
                ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                visible: false,

            };
        },
        created() {

            this.$root.$on('statusChangeDialog:show', this.show);

        },
        beforeDestroy() {

            this.$root.$off('statusChangeDialog:show', this.show);

        },
        methods: {
            
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            show(userID, status, onStatusChanged) {
                this.userID = userID;
                this.status = status;
                this.onStatusChanged = onStatusChanged;
                this.visible = true;
                this.load();
            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            load() {

                API.execute('admin/getUserStatuses', {
                    offset: 0,
                    count: 1000,
                }).then((data) => {
                    
                    if (data) {
                        var options = [];
                        data.forEach(element => {
                            options.push({
                                value: element.id,
                                name: element.name,
                            });
                        });
                        this.valueOptions = options;
                    }
                    else {
                        this.valueOptions = [];
                    }

                    this.selectOption();

                }).catch ((data) => {
                    
                    this.valueOptions = [];
                    
                    this.selectOption();

                });
                
            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            selectOption() {

                if (this.valueOptions) {
                    for (var i = 0; i < this.valueOptions.length; i++) {
                        var current = this.valueOptions[i];
                        if (current.name == this.status) {
                            this.value = current;
                            return;
                        }
                    }
                }

                this.value = null;

            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            getStatus() {

                if (this.value) {
                    return this.value.name;
                }

                return '';

            },
            
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            onStatusChange() {

                API.execute('admin/changeUserStatus', {
                    userID: this.userID,
                    status: this.getStatus(),
                }).then((response) => {
                    
                    if (response) {
                        if (this.onStatusChanged) {
                            this.onStatusChanged(this.userID, this.getStatus());
                            this.close();
                        }
                    }
                    else {
                        this.$root.$emit('messageBox:show', 'It is not possible to change the status of a user at this point in time. Please wait and retry your request.');
                        this.close();
                    }

                }).catch ((data) => {
                    
                    this.$root.$emit('messageBox:show', 'It is not possible to change the status of a user at this point in time. Please wait and retry your request.');
                    this.close();

                });

            },

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            close() {
                this.userID = 0;
                this.status = '';
                this.onStatusChanged = null;
                this.visible = false;
            },

        }
    }

</script>

<style scoped>

    .status-change-dialog {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }

    .background {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.5;
    }

    .dialog {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 500px;
        height: 230px;
        transform: translate(-50%, -50%);
        background: white;
        border-radius: 12px;
    }

    .content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 21px;
        box-sizing: border-box;
    }

    table {
        position: relative;
        width: 458px;
        height: auto;

        border-spacing: 10px;
        border-collapse: separate;
        box-sizing: border-box;
        table-layout: fixed;

        font-family: Golos;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 26px;
    }

    .column {
        position: relative;
        width: 100%;
        height: auto;
        vertical-align: top;
    }

    .label {
        position: relative;
        width: 100%;
        height: auto;
        font-size: 14px;
        padding: 0;
        box-sizing: border-box;
    }

    button {
        width: 100%;
        height: auto;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;

        padding: 15px 10px;
        margin: 10px 0 10px 0;
        border: 0;
        background: #2F80ED;
        border-radius: 4px;
        color: white;
        outline: none;
    }

    button:hover {
        background: #1e6acf;
    }

    button:active {
        background: #1959ae;
    }

</style>