/////////////////////////////////////////////////////////////////////////////////////

require('./base/cookies.js');
require('./base/load-script.js');
require('./base/meta.js');
require('./base/website.js');
require('./base/filters.js');
require('./base/closable-directive.js');

/////////////////////////////////////////////////////////////////////////////////////

require('./plugins/v-tooltip.js');
require('./plugins/v-multiselect.js');
require('./plugins/v-calendar.js');

/////////////////////////////////////////////////////////////////////////////////////

import Vue from 'vue'
import App from './App.vue'
import Router from './routing/router.js'
import API from './base/api.js'

/////////////////////////////////////////////////////////////////////////////////////
//	INITIALIZE VIEW MODEL

function init() {

  API.loadLocale('dashboard/base', function() {

    new Vue({
      el: '#app',
      router: Router,
      render: h => h(App)
    });

  });

}

/////////////////////////////////////////////////////////////////////////////////////
//	LOAD SCRIPTS

window.onload = function() {

  init();

};