<template>
  
  <div id="app">
    
    <table class='app-layout'>
      <tr>
        <td class='router-content'>
          
          <!-- CONTENT -->
          <router-view></router-view>

          <!-- MESSAGE BOX -->
          <x-message-box></x-message-box>
          
          <!-- USER STATUS CHANGE DIALOG -->
          <x-status-change-dialog></x-status-change-dialog>
          
          <!-- PASSWORD DIALOG -->
          <x-password-dialog></x-password-dialog>
          
          <!-- EMAIL SENDER DIALOG -->
          <x-email-sender-dialog></x-email-sender-dialog>

          <!-- NEW PRICE DIALOG -->
          <x-new-price-dialog></x-new-price-dialog>
          
          <!-- MASTER PASSWORD DIALOG -->
          <x-master-password-dialog></x-master-password-dialog>

          <!-- IMPORT EMAILS DIALOG -->
          <x-upload-emails-dialog></x-upload-emails-dialog>

          <!-- UNSUBSCRIBE EMAILS DIALOG -->
          <x-unsubscribe-emails-dialog></x-unsubscribe-emails-dialog>

        </td>
      </tr>
    </table>
    
  </div>

</template>

<script>

  import XMessageBox from './components/common/xmessagebox/XMessageBox.vue'
  import XStatusChangeDialog from './components/common/xstatuschangedialog/XStatusChangeDialog.vue'
  import XPasswordDialog from './components/common/XPasswordDialog/XPasswordDialog.vue'
  import XEmailSenderDialog from './components/common/XEmailSenderDialog/XEmailSenderDialog.vue'
  import XNewPriceDialog from './components/common/XNewPriceDialog/XNewPriceDialog.vue'
  import XUploadEmailsDialog from './components/common/XUploadEmailsDialog/XUploadEmailsDialog.vue'
  import XUnsubscribeEmailsDialog from './components/common/XUnsubscribeEmailsDialog/XUnsubscribeEmailsDialog.vue'
  import XMasterPasswordDialog from './components/common/xmasterpassworddialog/XMasterPasswordDialog.vue'
  
  export default {
    
    name: 'app',
    
    data () {
      return {}
    },
    components: {
      XMessageBox,
      XStatusChangeDialog,
      XPasswordDialog,
      XEmailSenderDialog,
      XNewPriceDialog,
      XUploadEmailsDialog,
      XUnsubscribeEmailsDialog,
      XMasterPasswordDialog,
    }

  }
  
</script>

<style>

    @import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,400i,700');

    body, * {
      font-family: 'Titillium Web', Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding: 0;
      margin: 0;
    }

    html, body {
      background: #edeef0;
      width: 100%;
      height: 100%;
    }

    .relative {
        position: relative;
    }

    .full {
        width: 100%;
        height: 100%;
    }

    .full-height {
        height: 100%;
    }

    .hidden {
        display: none;
    }

    .right {
      float: right;
    }
    

    .unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
    }

    #app {
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;
        height: 100%;
        min-height: 100%;
    }

    .app-layout {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .sidebar {
      position: relative;
      width: 57px;
      height: 100%;
    }

    .router-content {
      position: relative;
      width: calc(100% - 57px);
      height: 100%;
    }



    .page {
      position: relative;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background: #f7f9fc;
    }
    
    .wide-content {
      position: relative;
      width: 960px;
      height: auto;
      margin: 0 auto 0 auto;
      padding: 0 0 0 0;
      box-sizing: border-box;
    }


@media all and (min-width: 1600px) {
    /* WIDE SCREEN & PC */

    .content {
        position: relative;
        width: calc(50% - 40px);
        min-width: 1300px;
        height: auto;
        margin: 0 auto 0 auto;
    }

}

@media all and (min-width: 768px) and (max-width: 1599px) {
    /* TABLET DEVICES */

    .content {
        position: relative;
        width: calc(100% - 40px);
        height: auto;
        margin: 0 auto 0 auto;
    }

}

@media all and (max-width: 767px) {
    /* MOBILE DEVICES */

    .content {
        position: relative;
        width: calc(100% - 40px);
        height: auto;
        margin: 0 auto 0 auto;
    }

}
    

    .click-through {
      pointer-events: none;
    }

    .click-all {
      pointer-events: all;
    }

    p {
      padding: 5px 0 5px 0;
      box-sizing: border-box;
    }

    .sky-label {
      color: #0758ab;
      font-weight: 500;
    }

    .bold-label {
      font-weight: bold;
    }

    @import './styles/v-tooltip.css';
    @import './styles/v-multiselect.css';
    @import './styles/v-tabs.css';
    @import './styles/buttons.css';
    
</style>
