
import axios from 'axios'
import Vue from 'vue'

export default {

    locale(value) {
		
		if (typeof value === 'string') {
            
            window.$cookies.set('locale', value, '30D');
            
		}
        
        if (!window.$cookies.isKey("locale")) {
            return 'en-US';
        }
        
        return window.$cookies.get('locale');
		
    },

    execute: function(method, args) {
        var isEcufix = (window.location.host.indexOf('ecufix.info') >= 0),
            apiUrl = (isEcufix) ? 'https://api.ecufix.info/api/' : 'https://api.dumptools.com/api/',
            url = (apiUrl + method);
        
        if (!args) {
            args = {};
        }
        
        args.accessToken = this.accessToken();
        
        return new Promise((resolve, reject) => {
            
            $.ajax(url, {
            
                cache: false,
                method: 'POST',
                data: JSON.stringify(args),
                dataType: "application/json",
                headers: { 'Content-type': 'application/json' }
                
            }).done(function(data) {

                if ($.type(data) == 'string') {
                    try
                    {

                        data = JSON.parse(data);

                        if ($.type(data['response']) != 'undefined')  {
                            return resolve(data['response']);
                        }
                        
                        if (data['error']) {
                            return reject(data);
                        }
                        
                        return reject({
                            error: 1000,
                            message: 'Internal Server Error',
                        });

                    }
                    catch (e) {
                        return reject({
                            error: 1001,
                            message: 'Response Parse Error',
                            target: e,
                        })
                    }
                }
                else {

                    if ($.type(data['response']) != 'undefined') {
                        return resolve(data['response']);
                    }
                    
                    if (data['error']) {
                        return reject(data);
                    }
                    
                    return reject({
                        error: 1000,
                        message: 'Internal Server Error',
                    });

                }
            })
            .fail(function(e) {

                if (e.status == 200) {
                    
                    var data = e.responseText;
                    
                    if ($.type(data) == 'string') {
                        try
                        {
    
                            data = JSON.parse(data);
    
                            if ($.type(data['response']) != 'undefined') {
                                return resolve(data['response']);
                            }
                            
                            if (data['error']) {
                                return reject(data);
                            }
    
                            return reject({
                                error: 1000,
                                message: 'Internal Server Error',
                            });
    
                        }
                        catch (e) {
                            return reject({
                                error: 1001,
                                message: 'Response Parse Error',
                                target: e,
                            })
                        }
                    }
                    else {
    
                        if ($.type(data['response']) != 'undefined') {
                            return resolve(data['response']);
                        }
                        
                        if (data['error']) {
                            return reject(data);
                        }
                        
                        return reject({
                            error: 1000,
                            message: 'Internal Server Error',
                        });
                        
                    }
                    
                }
                else {
                    
                    return reject({
                        error: 1000,
                        message: 'Internal Server Error',
                        target: e,
                    });

                }
                
            });

        });

    },

    accessToken() {
        return $.cookie('accessToken');
    },

    setAccessToken(value) {
        $.cookie('accessToken', value, { path: '/' });
    },
    
    checkAccess(callback) {
        if (this.accessToken()) {
            callback(true);
        }
        else {
            callback(false);
        }
    },
    
    loadScript(url) {

        return Vue.loadScript(url);

    },

    loadLocale: function(relativePath, callback) {

        this.loadScript("/locales/" + this.locale() + "/" + relativePath + ".js?" + new Date().getTime())
        .then((response) => {
            if (callback) callback(true);
        })
        .catch((error) => {
            if (callback) callback(false);
        });
        
    },
    
};