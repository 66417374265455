<template>
    
    <div class='unsubscribe-emails-dialog' v-if='visible'>

        <div class='background' @click='hide'></div>

        <div class='dialog'>
            <div class='content2'>
                <table>
                    <tr>
                        <td class='column'>
                            <div class='header'>
                                Upload a text file
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class='column'>
                            <x-uploader @uploaded='onUploaded'></x-uploader>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </div>

</template>

<script>

    import XUploader from './components/XUploader.vue'
    
    export default {
        data() {
            return {

                /////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                isProcessing: false,

                /////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                visible: false,

            };
        },
        created() {

            this.$root.$on('unsubscribeEmailsDialog:show', this.show);

        },
        beforeDestroy() {

            this.$root.$off('unsubscribeEmailsDialog:show', this.show);

        },
        methods: {
            
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            show() {

                this.visible = true;

            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            hide() {

                this.visible = false;

            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  
            
            onUploaded(response) {
                
                if (response) {
                    this.$root.$emit('messageBox:show', 'The text file has been successfully uploaded for processing.');
                    this.hide();
                }

            },

        },
        components: {
            XUploader,
        }
    }

</script>

<style scoped>

    .unsubscribe-emails-dialog {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }

    .background {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.5;
    }

    .dialog {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 500px;
        height: 230px;
        transform: translate(-50%, -50%);
        background: white;
        border-radius: 12px;
    }

    .content2 {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 15px;
        box-sizing: border-box;
    }

    .header {
        position: relative;
        width: 100%;
        height: auto;
        padding: 0 10px 10px 0;
        box-sizing: border-box;
        font-weight: bold;
        font-size: 15px;
    }

</style>
