
// Documentation: https://github.com/Akryum/v-tooltip#usage

import Vue from 'vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip);

VTooltip.options.defaultDelay = { show: 1000, hide: 100 };
VTooltip.options.defaultOffset = 7;
VTooltip.options.defaultContainer = false;
VTooltip.options.defaultPlacement = 'bottom';