<template>

    <div class='email-sender-dialog' v-show='visible'>
        <div class='background' @click='hide'></div>
        <div class='form'>
            <div class='content2'>
                
                <input v-model='title' placeholder='Title' />

                <div class='editor'>
                    <vue-editor v-model='message'></vue-editor>
                </div>

                <x-upload-file @uploaded='onFileUploaded'></x-upload-file>

                <div class='button'>
                    <button @click='onSend'>
                        Send
                    </button>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
    
    import { VueEditor } from "vue2-editor";
    import XUploadFile from './components/XUploadFile.vue'
    import API from './../../../base/api'

    export default {
        data() {
            return {
                
                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  
                
                email: '',

                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                title: '',

                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                message: '',

                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                attachedFileID: 0,
                
                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                visible: false,

                //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //  

                isProcessing: false,

            };
        },
        created() {

            this.$root.$on('emailSenderDialog:show', this.show);

        },
        beforeDestroy() {

            this.$root.$off('emailSenderDialog:show', this.show);

        },
        methods: {

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            show(email) {

                this.title = '';
                this.message = '';
                this.email = email;
                this.visible = true;

            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            hide() {

                this.visible = false;

            },
            
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            onSend() {

                if (this.isProcessing) return;
                else this.isProcessing = true;

                API.execute('admin/sendEmail', {
                    email: this.email,
                    title: this.title,
                    message: this.message,
                    attachedFileID: this.attachedFileID,
                }).then((response) => {

                    this.isProcessing = false;
                    this.hide();

                    if (response) {
                        this.$root.$emit('messageBox:show', 'The message was successfully sent to the specified email.');
                    }
                    else {
                        this.$root.$emit('messageBox:show', 'The message was not sent to the specified email.');
                    }

                }).catch((errorCode) => {

                    this.isProcessing = false;
                    
                    this.hide();

                    if (errorCode == 10) {
                        this.$root.$emit('messageBox:show', 'It is not possible to send a message due to errors on the smtp server.');
                    }
                    else {
                        this.$root.$emit('messageBox:show', 'It is not possible to send a message due to a runtime error.')
                    }

                });

            },

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //  

            onFileUploaded(fileID) {
                this.attachedFileID = fileID;
            },
            
        },
        components: {
            VueEditor,
            XUploadFile,
        },
    }

</script>

<style scoped>

    .email-sender-dialog {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 1000;
    }

    .background {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: black;
        opacity: 0.5;
   }

    .form {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 80%;
        height: auto;
        transform: translate(-50%, -50%);
        z-index: 2;
        background: white;
    }

    .content2 {
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px;
        box-sizing: border-box;
    }

    .label {
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 10px 10px 0;
        box-sizing: border-box;
        color: black;
    }

    input {
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px;
        box-sizing: border-box;
        color: black;
        outline: none;
        margin: 0 0 10px 0;
    }

    .editor {
        position: relative;
        width: 100%;
        height: auto;
        max-height: 300px;
        background: white;
        margin: 0 0 10px 0;
        overflow: auto;
    }

    button {
        position: relative;
        width: auto;
        height: auto;
        min-width: 120px;
        border: 0;
        margin: 0 auto 10px auto;
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        border-radius: 4px;
        background: #2F80ED;

        font-family: Golos;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: white;
        float: right;
    }

    button:hover {
        background: #1d5db3;
        color: white;
    }

    button:active {
        background: #1d5db3;
        color: white;
    }

</style>